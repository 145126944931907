import React from "react";
import Cards from "../Cards/Cards";
import CoverCard from "../Cards/CoverCard";

function AllCovers() {
  return (
    <div>
      <CoverCard />
    </div>
  );
}

export default AllCovers;
